import React, { useState, useEffect, useRef } from "react"
import { Row, Col } from 'react-bootstrap'
import Navbar from '../../../side navbar/sidenav'
import axios from "axios"
import moment from 'moment'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPrint } from "@fortawesome/free-solid-svg-icons"
import Logo from '../../../../imgs/logo.png'
import ReactToPrint from "react-to-print";
import './report.css'

class PrintableContent extends React.Component {
    render() {
      const { tsm ,tsmr ,tsmnr ,tea ,teap ,teanp, fmd, tni, tvisa, tprofmm, tmtn, tairtel ,tmi , projectsAmount} = this.props;
      const today = new Date();
        const options = { dateStyle: 'short', timeStyle: 'short' };
        const formattedDateTime = new Intl.DateTimeFormat(undefined, options).format(today)
      return (
        <div style={{color:'black',padding:'20px'}}>
          <h1 style={{textAlign:'center'}}> <img src={Logo} alt="receipt-logo" style={{margin:'auto'}}  height='80px'/><br></br>Prof-bioresearch {localStorage.getItem('branch')} shop report</h1>
          <h5 style={{textAlign:'center'}}>Sales Points: Equatorial near bank of Africa, Masanafu near padre pio vocational school</h5>
          <h5 style={{textAlign:'center'}}>Web: www.profbioresearch.net</h5>
          <h5 style={{textAlign:'center'}}>Email: profbioresearch@gmail.com</h5>
          <h5 style={{textAlign:'center',borderBottom:'1px dashed black'}}>Contact: 0702061652 / 0779519652</h5>
          <p style={{marginTop:'40px'}}>Printed On Date: {formattedDateTime}</p>
          <p>Report For Date: {fmd}</p>
          <p style={{textAlign:'center', fontSize:'20px'}}>DAILY PROFIT/LOSS ANALYSIS REPORT</p>
          <table className="table table-light bordered-table">
                <tr>
                  <td><strong>Total Amount From Sales: UGX </strong></td>
                  <td>{tsm}</td>
                </tr>
                <tr>
                  <td><strong>Total Sales Amount Recieved: UGX</strong></td>
                  <td>{tsmr}</td>
                </tr>
                <tr>
                  <td><strong>Total Sales Amount Not Recieved: UGX</strong></td>
                  <td>{tsmnr}</td>
                </tr>
                <tr>
                  <td><strong>Total Expenditure Amount: UGX</strong></td>
                  <td>{tea}</td>
                </tr>
                <tr>
                  <td><strong>Total Expenditure Amount Paid: UGX</strong></td>
                  <td>{teap}</td>
                </tr>
                <tr>
                  <td><strong>Total Expenditure Amount Not Paid: UGX</strong></td>
                  <td>{teanp}</td>
                </tr>
                <tr>
                  <td><strong>Total Net Income Available (Cash): UGX</strong></td>
                  <td>{tni}</td>
                </tr>
                <tr>
                  <td><strong>Total Net Income Available (MoMo): UGX</strong></td>
                  <td>{tmtn + tairtel + tprofmm }</td>
                </tr>
                <tr>
                  <td><strong>Total Net Income Available (Visa): UGX</strong></td>
                  <td>{tvisa}</td>
                </tr>
                <tr>
                  <td><strong>Total Massage Income: UGX</strong></td>
                  <td>{tmi}</td>
                </tr>
                <tr>
                  <td><strong>Total Projects Income: UGX</strong></td>
                  <td>{projectsAmount}</td>
                </tr>
                <tr>
                  <td><strong>Total Net Income Available (Cash) (Shop+Massage+Projects): UGX</strong></td>
                  <td>{tni+tmi+projectsAmount}</td>
                </tr>
            </table>
          <p>Printed By: {localStorage.getItem('username')}</p>
          <p style={{ marginTop: '5px', marginBottom:'5px', fontSize: '13px', borderTop:'1px solid black', textAlign:'center' }}>
            Report Generated By {localStorage.getItem('branch')} Prof-Bioresearch Management System.
          </p>
        </div>
      );
    }
}

const EquatorialShopDailySalesReport = () => {
    const [selectedDay, setSelectedDay] = useState()
    const [salesData, setSalesData] = useState([])
    const [filteredSales, setFilteredSales] = useState([])
    const [filteredExpenses, setFilteredExpenses] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [expensesData, setExpensesData] = useState([])
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalAmountPaid, setTotalAmountPaid] = useState(0);
    const [balance, setBalance] = useState(0)
    const [projectsAmount, setProjectsAmount] = useState(0)


    const [totalExpenditureAmount, setTotalExpenditureAmount] = useState(0);
    const [totalExpenditureAmountPaid, setTotalExpenditureAmountPaid] = useState(0);
    const [expenditureBalance, setExpenditureBalance] = useState(0)
    const [massageData,  setMassageData] = useState([])
    const [projectsData,  setProjectsData] = useState([])
    const [filteredMassageData,  setFilteredMassageData] = useState([])
    const [isCalculationsLoading, setIsCalculationsLoading] = useState(true)
    const [isExpensesLoading, setIsExpensesLoading] = useState(true)

    const [totalMassageAmount, setTotalMassageAmount] = useState(0)

    const [formattedDate, setFormattedDate] = useState('')

    const componentRef = useRef()
    const printBtnRef = useRef()

    const [paymentMethodTotals, setPaymentMethodTotals] = useState({
        Cash: 0,
        Visa: 0,
        MTNMoMo: 0,
        AirtelMoney: 0,
        ProfMM: 0,
      });
      const [expensePaymentMethodTotals, setExpensePaymentMethodTotals] = useState({
        Cash: 0,
        Visa: 0,
        MTNMoMo: 0,
        AirtelMoney: 0,
        ProfMM: 0,
      });

      let paymentMethods = {
        Cash: 0,
        Visa: 0,
        MTNMoMo: 0,
        AirtelMoney: 0,
        ProfMM: 0,
      }

      let ExpensepaymentMethod = {
        Cash: 0,
        Visa: 0,
        MTNMoMo: 0,
        AirtelMoney: 0,
        ProfMM: 0,
      }


     const selectedDateHandler = (event) => {
         event.preventDefault()
         setSelectedDay(event.target.value)
     };

     const fetchSalesData = async () => {
      let res = await axios.post('http://195.35.11.24:3005/fetchallequatorialshopsalesreport', {
        token: localStorage.getItem('token'),
        date: formattedDate
      });
  
      if (Array.isArray(res.data)) {
        setIsLoading(false);
        setSalesData(res.data);
        setFilteredSales(res.data)
      }
    };

    const fetchExpensesData = async () => {
      let res = await axios.post('http://195.35.11.24:3005/fetchallequatorialshopexpensesreport', {
        token: localStorage.getItem('token'),
        date: formattedDate
      });
  
      if (Array.isArray(res.data)) {
        setIsExpensesLoading(false);
        setExpensesData(res.data);
        setFilteredExpenses(res.data)
      }
    }

    const fetchMassageData = async () => {
      let res = await axios.post('http://195.35.11.24:3005/fetchamassagerecordsreport', {
        token: localStorage.getItem('token'),
        date: formattedDate
      })
  
      if (Array.isArray(res.data)) {
        const receivedMassageData = res.data.filter((item) => item.submissionstatus === "recieved");
        setMassageData(receivedMassageData );
      }
    }

    const fetchProjectsData = async () => {
      let res = await axios.post('http://195.35.11.24:3005/fetchaprojectsrecordsreport', {
        token: localStorage.getItem('token'),
        date: formattedDate
      })
  
      if (Array.isArray(res.data)) {
        setProjectsData(res.data);
        const formattedDate = moment(selectedDay).format('DD/MM/YYYY')

        const filteredData = res.data.filter((data)=>{
          const saleDate = moment(data.submissionDate, 'DD/MM/YYYY').format('DD/MM/YYYY');
          return saleDate === formattedDate
        })

        let totalAmount = filteredData.reduce((acc, curr) => acc + curr.amount, 0);

        setProjectsAmount(totalAmount)
      }
    }


    const generateReport = async event => {
      event.preventDefault()
      setProjectsAmount(0)
      await fetchSalesData()
      await fetchExpensesData()
      await fetchMassageData()
      await fetchProjectsData()
    }


    useEffect(()=>{
        const formattedDate = moment(selectedDay).format('DD/MM/YYYY')
        setFormattedDate(JSON.stringify(formattedDate))

       if (formattedDate) {
            const filteredSalesData = salesData.filter((sale) => {
                const saleDate = moment(sale.saleDate, 'DD/MM/YYYY').format('DD/MM/YYYY');
                return saleDate === formattedDate;
            });

            const filteredExpensesData = expensesData.filter((expense) => {
                const expenseDate = moment(expense.date, 'DD/MM/YYYY').format('DD/MM/YYYY');
                return expenseDate === formattedDate;
            });

            setFilteredSales(filteredSalesData)
            setFilteredExpenses(filteredExpensesData)
       }

    },[selectedDay])

    useEffect(()=>{
      const formattedDate = moment(selectedDay).format('MM/DD/YYYY')

      const filteredData = massageData.filter((data)=>{
        const saleDate = moment(data.submissionDate, 'MM/DD/YYYY').format('MM/DD/YYYY');
        return saleDate === formattedDate
      })
      setFilteredMassageData(filteredData)

      let grandTotalMassageAmount = 0;
      let grandTotalProductAmount = 0;

      // Calculate totals for each object and accumulate them
      filteredData.forEach(item => {
          const totalMassageAmount = item.massageamount;
          const totalProductAmount = item.productamount;

          grandTotalMassageAmount += totalMassageAmount;
          grandTotalProductAmount += totalProductAmount;
      })
      setTotalMassageAmount(grandTotalMassageAmount+grandTotalProductAmount)

  },[massageData, selectedDay])


    useEffect(() => {
        let totalAmount = 0;
        let totalAmountPaid = 0;
        let balance = 0;
      
        let totalExpenseAmount = 0;
        let totalExpenseAmountPaid = 0;
        let expenseAmountNotPaid = 0;

      
        filteredSales.forEach((sale) => {
          totalAmount += sale.totalAmount;
          totalAmountPaid += sale.totalAmount - sale.balance;
          balance += sale.balance;

          if (sale.paymentMethod === 'Cash') {
            paymentMethods.Cash += sale.totalAmount-sale.balance;
          } else if (sale.paymentMethod === 'Prof MM') {
            paymentMethods.ProfMM += sale.totalAmount-sale.balance;
          } else if (sale.paymentMethod === 'Visa') {
            paymentMethods.Visa += sale.totalAmount-sale.balance;
          }else if (sale.paymentMethod === 'MTN MoMo') {
            paymentMethods.MTNMoMo += sale.totalAmount-sale.balance;
          }else if (sale.paymentMethod === 'Airtel Money') {
            paymentMethods.AirtelMoney += sale.totalAmount-sale.balance;
          }
        });
      
        filteredExpenses.forEach((expense) => {
          totalExpenseAmount += expense.expenditurecost;
          totalExpenseAmountPaid += expense.amountspent;
          expenseAmountNotPaid += expense.balance;

          if (expense.paymentmethod === 'Cash') {
            ExpensepaymentMethod.Cash += expense.amountspent;
          } else if (expense.paymentmethod === 'Prof MM') {
            ExpensepaymentMethod.ProfMM += expense.amountspent;
          } else if (expense.paymentmethod === 'Visa') {
            ExpensepaymentMethod.Visa += expense.amountspent;
          }else if (expense.paymentmethod === 'MTN MoMo') {
            ExpensepaymentMethod.MTNMoMo += expense.amountspent;
          }else if (expense.paymentmethod === 'Airtel Money') {
            ExpensepaymentMethod.AirtelMoney += expense.amountspent;
          }
        });
      
        setTotalAmount(totalAmount);
        setTotalAmountPaid(totalAmountPaid);
        setBalance(balance);
        setPaymentMethodTotals(paymentMethods)
        setExpensePaymentMethodTotals(ExpensepaymentMethod)
      
        setTotalExpenditureAmount(totalExpenseAmount);
        setTotalExpenditureAmountPaid(totalExpenseAmountPaid);
        setExpenditureBalance(expenseAmountNotPaid);
        setIsCalculationsLoading(false);
      }, [filteredSales, filteredExpenses]);
      
    return(
        <div style={{backgroundColor:'#E9E9E9', color:'black'}}>
            <Row>
                <Col sm='12' md='1' lg='1' xl='1'>
                    <Navbar />
                </Col>
                <Col sm='12' md='10' lg='10' xl='10'>
                    <h1 style={{textAlign:'center', color:'black',marginTop:'60px'}}>Equatorial Shop Daily Report <span style={{float:'right',marginRight:'10px'}}>
                        <ReactToPrint
                        trigger={() => (
                          <button style={{border:'none',backgroundColor:'#E9E9E9'}} ref={printBtnRef}><FontAwesomeIcon icon={faPrint}/></button>
                        )}
                        content={() => componentRef.current}
                        pdfPrint={true}
                        />
                        <div className="print-content">
                            <PrintableContent ref={componentRef} tsm={totalAmount} tsmr={totalAmountPaid} tsmnr={balance} tea={totalExpenditureAmount} teap={totalExpenditureAmountPaid} teanp={expenditureBalance} fmd={formattedDate} tni={paymentMethodTotals.Cash-expensePaymentMethodTotals.Cash} tmtn={paymentMethodTotals.MTNMoMo-expensePaymentMethodTotals.MTNMoMo} tairtel={paymentMethodTotals.AirtelMoney-expensePaymentMethodTotals.AirtelMoney} tprofmm={paymentMethodTotals.ProfMM-expensePaymentMethodTotals.ProfMM} tvisa={paymentMethodTotals.Visa-expensePaymentMethodTotals.Visa} tmi={totalMassageAmount} projectsAmount={projectsAmount}/>
                        </div>
                        <style>
                            {`
                            .print-content {
                                display: none
                            }
                            `}
                        </style>
                    </span></h1>
                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <label htmlFor="date-select" style={{color:'black'}}>Select The Day:</label>
                        <input type="date" id="date-select" className="form-control" style={{ width: '300px' }} onChange={selectedDateHandler}/>
                        <button onClick={generateReport}>Fetch Data</button>
                    </div>
                    <div>
                        <h5 style={{ textAlign: 'center', color: 'black', marginTop: '40px' }}>
                        </h5>
                        <h1 style={{textAlign:'center', color:'black',marginTop:'40px'}}>Daily Sales List</h1>
                        <table className="table table-light" style={{ marginTop: '20px',textAlign:'center' }}>
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">Receipt No.</th>
                                    <th scope="col">Sale Date</th>
                                    <th scope="col">Customer Names</th>
                                    <th scope="col">Customer Contact</th>
                                    <th scope="col">Items Sold</th>
                                    <th scope="col">Total Sale Amount</th>
                                    <th scope="col">Amount Paid</th>
                                    <th scope="col">Balance</th>
                                    <th scope="col">Additional Notes</th>
                                    <th scope="col">Payment Method</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading ? filteredSales.map(item => (
                                    <tr>
                                        <td>{item.receiptNumber}</td>
                                        <td>{item.saleDate}</td>
                                        <td>{item.customerNames}</td>
                                        <td>{item.customerContact}</td>
                                        <td>
                                        <table className="table table-light" style={{ marginTop: '2px' }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Item Name</th>
                                                        <th scope="col">Quantity</th>
                                                        <th scope="col">Unit Price (UGX)</th>
                                                        <th scope="col">Discount</th>
                                                        <th scope="col">Total Cost (UGX)</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ textAlign: 'center' }}>
                                                    {JSON.parse(item.itemsSold).map(itemordered =>
                                                        <tr>
                                                                <td>{itemordered.name}</td>
                                                                <td>{itemordered.quantity}</td>
                                                                <td>{itemordered.unitCost}</td>
                                                                <td>{itemordered.discount}</td>
                                                                <td>{itemordered.totalCost}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </td>
                                        <td>{item.totalAmount}</td>
                                        <td>{item.totalAmount-item.balance}</td>
                                        <td>{item.balance}</td>
                                        <td>{item.additionalinfo}</td>
                                        <td>{item.paymentMethod}</td>
                                    </tr>
                                ))
                                : <tr><td colSpan='9'>No data...</td></tr>}
                                    {/* Grand total row */}
                                        {!isLoading && filteredSales.length > 0 && (
                                        <tr>
                                            <td colSpan="4"></td>
                                            <td>
                                            <strong>
                                                Grand Total:
                                            </strong>
                                            </td>
                                            <td>
                                            <strong>
                                                {filteredSales.reduce((total, item) => total + item.totalAmount, 0)}
                                            </strong>
                                            </td>
                                            <td>
                                            <strong>
                                                {filteredSales.reduce((total, item) => total + (item.totalAmount - item.balance), 0)}
                                            </strong>
                                            </td>
                                            <td>
                                            <strong>
                                                {filteredSales.reduce((total, item) => total + item.balance, 0)}
                                            </strong>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        )}
                            </tbody>
                        </table>
                        <h2 style={{textAlign:'center', marginTop:'60px',color:'black'}}>Daily Expenditure List</h2>
                        <table className="table table-light" style={{ marginTop: '20px',textAlign:'center' }}>
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">Expenditure Id</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Expense Category</th>
                                    <th scope="col">Expense Name</th>
                                    <th scope="col">Expense Description</th>
                                    <th scope="col">Total Cost</th>
                                    <th scope="col">Amount Paid</th>
                                    <th scope="col">Balance</th>
                                    <th scope="col">Payment Method</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isExpensesLoading ? filteredExpenses.map(item => (
                                    <tr>
                                        <td>{item.expenditureid}</td>
                                        <td>{item.date}</td>
                                        <td>{item.expenditurecategory}</td>
                                        <td>{item.expenditurename}</td>
                                        <td>{item.expendituredescription}</td>
                                        <td>{item.expenditurecost}</td>
                                        <td>{item.amountspent}</td>
                                        <td>{item.balance}</td>
                                        <td>{item.paymentmethod}</td>
                                    </tr>
                                ))
                                : <tr><td colSpan='8'>Loading...</td></tr>}
                                {/* Grand total row */}
                                {!isExpensesLoading && filteredExpenses.length > 0 && (
                                        <tr>
                                            <td colSpan="4"></td>
                                            <td>
                                            <strong>
                                                Grand Total:
                                            </strong>
                                            </td>
                                            <td>
                                            <strong>
                                                {filteredExpenses.reduce((total, item) => total + item.expenditurecost, 0)}
                                            </strong>
                                            </td>
                                            <td>
                                            <strong>
                                                {filteredExpenses.reduce((total, item) => total + (item.expenditurecost - item.balance), 0)}
                                            </strong>
                                            </td>
                                            <td>
                                            <strong>
                                                {filteredExpenses.reduce((total, item) => total + item.balance, 0)}
                                            </strong>
                                            </td>
                                            <td></td>
                                        </tr>
                                        )}
                            </tbody>
                        </table>
                    </div>
                </Col>
                <Col sm='12' md='1' lg='1' xl='1'></Col>
            </Row>
            <Row style={{marginTop:'50px'}}></Row>

        </div>
    )
}

export default EquatorialShopDailySalesReport